<template>
    <div class="appeal-main">
        <div class="appeal-main__wrapper">

            <section class="appeal-content">

                <h1>Обращения граждан</h1>

                <!-- статистика обращений -->
                <div class="appeal-content__information">
                  <div>
                    <p>Информируем, что реализация Вами права на обращение в форме электронного документа осуществляется в соответствии с российским законодательством с обеспечением защиты прав.</p>
                    <div class="group-button">
                        <router-link tag="button" to="/appeal_forms" class="btn-red">Подать обращение</router-link>
<!--                      <button class="btn-red"><a class="btn-red" href="https://oprflk.dev.devfbit.ru">Подать обращение</a></button>-->
                        <button class="btn-white">Узнать статус обращения</button>
                    </div>
                  </div>
                  <div>
                    <p class="filed">
                      <span>подано</span>
                      <span class="number-all">799</span>
                    </p>
                    <p class="result">
                      <span>в работе</span>
                      <span>/ 215</span>
                    </p>
                  </div>
                  <div>
                    <p class="resolved">
                      <span>Решено</span>
                      <span class="number-all">576</span>
                    </p>
                    <p class="result">
                      <span>за неделю</span>
                      <span>/ 42</span>
                    </p>
                  </div>
                </div>

                <!-- последние обращения -->
                <div class="appeal-content__recent">
                    <div class="appeal-content__header">
                        <h3 class="sub-title_categories">Последние обращения</h3>
                        <router-link to="/appeal" class="btn-blue">Все обращения</router-link>
                    </div>
                    <tricolor-line />

                    <div class="appeal-content__recent-wrapper">
                        <div class="appeal-content__recent-tags">
                            <h3 class="sub-title_categories">За неделю  с 23.03.2020 по 30.03.2020</h3>
                            <span v-for="(tag, key) in tags_appeal" :key="`tag${key}`">{{ tag }}</span>
                        </div>
                        <Carousel class="card_container" :count="2" key="video">
                            <appeal-card v-for="key in 2" :key="`link${key}`" :card="getAppeal[key]" />
                        </Carousel>
                    </div>
                </div>

            </section>

            <section class="appeal-steps">
                <h1>Как мы помогаем решать проблемы ?</h1>
                    <div>
                        <!-- 1 step -->
                        <div class="solution-problem__steps">
                            <span class="step-bg"></span>
                            <p class="step-number"><span>1</span>шаг
                            <svg width="499" height="341" viewBox="0 0 499 341" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99993 1C4.99993 1 -20 116 57.4999 166C135 216 216.999 211.5 351.999 233C486.999 254.5 497.5 340 497.5 340" stroke="#D6E6F5" stroke-width="2"/>
                            </svg>
                            <svg class="tablet" width="2" height="516" viewBox="0 0 2 516" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0L0.999977 516" stroke="#D6E6F5" stroke-width="2"/>
                            </svg>
                            </p>
                            <div class="description-step">
                                <p class="step-title">Опишите вашу <br>проблему в заявке</p>
                                <span class="step-line"></span>
                                <p class="step-text">Расскажите о проблеме, которую просите решить, заполнив простые поля в заявке: тема, краткое описание проблемы, адрес.</p>
                                <p class="step-text">Укажите то, что вам известно - при необходимости наш сотрудник свяжется с Вами для уточнения деталей.</p>
                            </div>
                            <div class="complementary-step">
                                <p class="complementary-step__number"><span>+15</span><span>за сегодня</span></p>
                                <router-link tag="button" to="/appeal_forms" class="btn-red">Подать обращение</router-link>
                                <p class="step-text">Вы можете поделиться страницей обращения в соцсетях.</p>
                                <ShareSocial :view_text=false />
                            </div>
                        </div>
                        <!-- 2 step -->
                        <div class="solution-problem__steps">
                            <span class="step-bg"></span>
                            <p class="step-number"><span>2</span>шаг
                                <svg width="487" height="363" viewBox="0 0 487 363" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M485 0.373047C485 0.373047 496 122.873 385 191.873C274 260.873 143 218.873 70 261.373C-3 303.873 1.49999 362.873 1.49999 362.873" stroke="#D6E6F5" stroke-width="2"/>
                                </svg>
                                <svg class="tablet" width="2" height="516" viewBox="0 0 2 516" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 0L0.999977 516" stroke="#D6E6F5" stroke-width="2"/>
                                </svg>
                            </p>
                            <div class="description-step">
                                <p class="step-title">Мы проверим и передадим вашу заявку в работу</p>
                                <span class="step-line"></span>
                                <p class="step-text">Каждая заявка проходит проверку, после чего передается в работу ответственной комиссии Общественной Палаты и публикуется на сайте с указанием исполнителейи возможных сроков решения. Вы сможете отслеживать статусы заявки прямона сайте, а также получать уведомления об изменениях.</p>
                                <p class="step-text">Чтобы Ваши знакомые могли поддержать обращение и привлечь к нему больше внимания.</p>
                            </div>
                            <div class="complementary-step">
                                <div class="complementary-step__appeal-card">
                                    <appeal-card v-for="key in 2" :key="`link${key}`" :card="getAppeal[key]" />
                                </div>
                            </div>
                        </div>
                        <!-- 3 step -->
                        <div class="solution-problem__steps">
                            <span class="step-bg"></span>
                            <p class="step-number"><span>3</span>шаг</p>
                            <div class="description-step">
                                <p class="step-title">Мы опубликуем решение по вашему обращению</p>
                                <span class="step-line"></span>
                                <p class="step-text">В указанный срок на странице заявки будет опубликован официальный ответ ответственного исполнителя по результатам работы над рассказанной Вами проблемой:о том, как проблема решена, или иное принятое решение.</p>
                                <p class="step-text">Вы сможете оставить свою оценку полученному результату и дать комментарий о том, насколько он удовлетворил Вас.</p>
                            </div>
                            <div class="complementary-step">
                                <figure class="complementary-step__avatar" :style="`background: url('/assets/temp/image4.png') center / cover`"></figure>
                                <div class="complementary-step__quote">
                                    <svg class="angle" width="49" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 26.5L49 0l-7 26.5H0z" fill="#fff"/></svg>
                                    <svg class="rigth" width="12" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.0105 9.299c2.0829-1.2675 3.0943-2.5757 3.0943-4.7613-.1371.0406-.2571.0406-.3771.0406-1.0886 0-2.1429-.6987-2.1429-1.9581 0-1.3081.8829-2.1206 2.1429-2.1206 1.6285 0 2.5628 1.235 2.5628 3.4531 0 3.0875-1.5 5.3056-4.3028 6.8412l-.9772-1.495zm-6 0c2.0829-1.2675 3.0943-2.5757 3.0943-4.7613-.1371.0406-.2571.0406-.3771.0406-1.0886 0-2.1429-.6987-2.1429-1.9581 0-1.3081.8829-2.1206 2.1429-2.1206 1.62 0 2.5543 1.235 2.5543 3.4531 0 3.0875-1.5 5.3056-4.3029 6.8412L.002 9.2989h.0085z" fill="#5A79A8"/></svg>
                                     Для человека, который знаком с исследованиями в области когнитивной психологии и читал все эти популярные книги на тему того, как мы принимаем решения. Спасибо!
                                    <svg class="left" width="12" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.0105 9.299c2.0829-1.2675 3.0943-2.5757 3.0943-4.7613-.1371.0406-.2571.0406-.3771.0406-1.0886 0-2.1429-.6987-2.1429-1.9581 0-1.3081.8829-2.1206 2.1429-2.1206 1.6285 0 2.5628 1.235 2.5628 3.4531 0 3.0875-1.5 5.3056-4.3028 6.8412l-.9772-1.495zm-6 0c2.0829-1.2675 3.0943-2.5757 3.0943-4.7613-.1371.0406-.2571.0406-.3771.0406-1.0886 0-2.1429-.6987-2.1429-1.9581 0-1.3081.8829-2.1206 2.1429-2.1206 1.62 0 2.5543 1.235 2.5543 3.4531 0 3.0875-1.5 5.3056-4.3029 6.8412L.002 9.2989h.0085z" fill="#5A79A8"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section class="appeal-maps">
                <div class="appeal-maps__total-appeals">
                    <p class="appeal-maps__title">Все обращения по России на карте</p>
                    <div>
                        <p class="appeal-maps__number-title">Обращений по России</p>
                        <p class="complementary-step__number"><span>3299</span><span></span></p>
                    </div>
                    <button class="btn-red">Смотреть на карте</button>
                </div>
                <figure></figure>
            </section>

        </div>
    </div>
</template>
<script>
import TricolorLine from '@/components/TricolorLine'
import AppealCard from '@/components/appeal/AppealCard'
import ShareSocial from '@/components/news/ShareSocial'
import Carousel from '@/components/Carousel'

export default {
  name: 'AppealMain',
  components: {
    TricolorLine,
    ShareSocial,
    AppealCard,
    Carousel
  },
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Обращения граждан'
        }
      ],
      tags_appeal: ['Здравоохранение (124)', 'Семья, материнство, д... (124)', 'Экология (124)', 'ЖКХ, строительство и дороги (124)', 'Образование и наука (124)', 'Экономика и предпри... (12)', 'Социальная политика (124)', 'Некоммерческие ор... (124)', 'Территориальное развитие и местное самоупр... (124)', 'Общественная дипломатия (124)', 'Еще темы']
    }
  },
  mounted () {
    this.$store.dispatch('getAppeal')
  },
  computed: {
    getAppeal () {
      return this.$store.getters.getAppeal.appeal || 0
    }
  }
}
</script>
<style lang="scss" scoped>

.appeal-main {
  background-color: white;
  overflow: hidden;
  .btn-red {
    padding: 0.5rem 2.37rem;
  }
  h1 {
    font-weight: bold;
    font-size: 3.4375rem;
    line-height: 110%;
    color: #1F3245;
    width: 50%;

    margin-bottom: 2.5rem;
}

  &__wrapper {
    width: 85.37rem;
    margin: 0 auto;

    .complementary-step {
        margin-left: 8.5rem;
        &__number {
            position: relative;
            z-index: 10;
            margin-bottom: 3.325rem;
            color: #246CB7;
            span:first-child {
                font-weight: bold;
                font-size: 5.5rem;
                line-height: 1;
            }
            span:last-child  {
                font-weight: 600;
                font-size: 1rem;
                margin-left: 0.5rem;
            }
        }
        &__number::before {
            content: '';
            position: absolute;
            width: 8rem;
            height: 8rem;
            left: 4rem;
            top: -1rem;
            background: #EEF4FA;
            border-radius: 100%;
            z-index: -1;
        }
        button {
            margin-bottom: 3rem;
        }
    }

    .appeal-content {
        padding-top: 5.375rem;
        background: url('../../assets/bg/bg_man_appeal.svg');
        background-position-x: right;
        background-repeat: no-repeat;
        background-size: 60%;

        &__information{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5rem;

            div:nth-child(1){
                width: 34.75rem;
                font-weight: 600;
                font-size: 0.875rem;
                color: rgba(23, 23, 23, 1);
                line-height: 157%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: self-start;
                padding-bottom: 0.878rem;
            }
            div:nth-child(2){
                display: flex;
                width: 20.3rem;
            }
            div:nth-child(3){
                display: flex;
                width: 20.3rem;
            }
            .filed,
            .resolved {
                display: flex;
                flex-direction: column;
                color: #246CB7;
                font-weight: bold;
                margin-right: 0.875rem;

                span:first-child {
                font-size: 1.125rem;
                line-height: 133%;
                text-transform: uppercase;
                margin-bottom: 1rem;
                }
                span:last-child {
                font-size: 7.5rem;
                line-height: 1;
                }
            }
            .resolved {
                color: #8ABD1F;
            }
            .group-button {
                width: 100% !important;
                display: flex;
                button:not(:last-child){
                    margin-right: 1rem;
                }
            }
            .result {
                display: flex;
                flex-direction: column;
                color: #1F3245;
                margin-top: auto;
                span:first-child {
                font-weight: 600;
                font-size: 1rem;
                line-height: 125%;
                }
                span:last-child {
                font-weight: bold;
                font-size: 2.75rem;
                }

            }
        }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__recent{
            margin-bottom: 6.125rem;

            .line_wrapper {
                margin: 1rem 0 2rem;
            }

            &-wrapper {
                display: flex;
                justify-content: space-between;
            }

            &-tags {
                display: flex;
                flex-wrap: wrap;
                width: 27rem;
                flex-shrink: 0;
                margin-right: 3rem;
                span {
                    margin-top: 1rem;
                    margin-right: 0.75rem;
                    background: #EEF4FA;
                    border-radius: 4px;
                    padding: 0.45rem 0.75rem;

                    font-weight: 600;
                    font-size: 0.8125rem;
                    line-height: 154%;
                    color: #246CB7;
                }
            }
        }

    }

    .appeal-steps {
        h1 {
            margin-bottom: 6.25rem;
        }
        .solution-problem{

            &__steps {
                display: flex;
                margin-bottom: 11.25rem;
                position: relative;
                .step-bg {
                    width: 26.25rem;
                    height: 26.25rem;
                    position: absolute;
                    background: url('../../assets/bg/bg_ellipse.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 0;
                    left: -16rem;
                    top: -20rem;
                }

                .step-number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    flex-shrink: 0;
                    width: 12.5rem;
                    height: 12.5rem;
                    background: url('../../assets/bg/step.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    color: #246CB7;
                    font-weight: 600;
                    font-size: 1rem;
                    margin-top: -4rem;
                    position: relative;

                    span{
                        font-weight: bold;
                        font-size: 3.4375rem;
                        line-height: 1;
                    }
                    svg {
                        position: absolute;
                        left: 35%;
                        top: 85%;
                        width: 31rem;
                        height: 21.1875rem;
                    }
                    .tablet{
                        display: none;
                    }
                }
                .step-title {
                    font-weight: bold;
                    font-size: 2.25rem;
                    line-height: 122%;
                    letter-spacing: 0.5px;
                    color: #003E78;
                    margin-bottom: 1.5rem;
                }
                .step-line {
                    width: 5.375rem;
                    display: block;
                    border-bottom: 1px solid #D6E6F5;
                    margin-bottom: 1.5rem;
                }
                .step-text {
                    font-size: 0.875rem;
                    line-height: 157%;
                    color: rgba(23, 23, 23, 0.8);
                    margin-bottom: 1.625rem;
                }
                .share-social{
                    margin-top: -.6rem;
                }

                .description-step {
                    width: 33.75rem;
                }
            }
            &__steps:nth-child(2) {
                margin-bottom: 9rem;
                .step-bg {
                    width: 47.25rem;
                    height: 47.25rem;
                    right: -23rem;
                    left: unset;
                    top: -20rem;
                }
                .step-number{
                    order: 2;
                    svg {
                        position: absolute;
                        right: 40%;
                        left: unset;
                        top: 90%;
                        width: 31rem;
                        height: 21.1875rem;
                    }
                }
                .description-step{
                    order: 3;
                }
                .complementary-step{
                    order: 1;
                    margin-left: 0;
                    &__appeal-card {
                        width: 31rem;
                        position: relative;
                        margin-top: -4rem;
                        margin-left: -1rem;
                        .appeal {
                            position: absolute;
                        }
                        .appeal:nth-child(1) {
                            transform: scale(0.6);
                            opacity: 0.66;
                            filter: blur(2.10674px);
                            top: -6rem;
                            left: -7rem;
                            z-index: 0;
                        }
                        .appeal:nth-child(2) {
                            transform: scale(0.7);
                            top: 0;
                            left: 0;
                            z-index: 5;
                            background: #fff;
                        }
                    }
                }
            }
            &__steps:nth-child(3) {
                .step-bg {
                    width: 47.25rem;
                    height: 47.25rem;
                    right: unset;
                    left: -23rem;
                    top: -13rem;
                }
                .complementary-step{
                    width: 34.875rem;
                    background: url('../../assets/bg/bg_step_3.png');
                    background-position-y: -1.3rem;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -3rem;

                    &__avatar{
                        width: 8rem;
                        height: 8rem;
                        border-radius: 100%;
                        border: 0.4rem solid #fff;
                        margin-top: 6.25rem;
                        box-shadow: 0px 10px 77px rgba(129, 146, 162, 0.77);
                    }
                    &__quote{
                        margin-top: 1rem;
                        background-color: white;
                        padding: 1.5rem;
                        position: relative;
                        display: flex;

                        font-style: italic;
                        font-size: 1.125rem;
                        line-height: 156%;
                        color: #5A79A8;

                        filter: drop-shadow(0px 26px 77px rgba(189, 205, 221, 0.33));
                    }
                    .angle{
                        position: absolute;
                        left: 32%;
                        bottom: 94%;
                        width: 3rem;
                    }
                    .rigth,
                    .left {
                        width: 3rem;
                        height: 100%;
                    }
                    .rigth{
                        margin-bottom: auto;
                        margin-right: 0.5rem;
                    }
                    .left{
                        margin-top: auto;
                        margin-left: 0.5rem;
                    }
               }
            }

        }
    }

    .appeal-maps {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3.125rem;

        &__title {
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 122%;
            letter-spacing: 0.5px;
            color: #1F3245;
            margin-bottom: 1.875rem;
            margin-top: 3rem;
        }
        &__number-title {
            font-weight: 600;
            font-size: 1rem;
            line-height: 150%;
            color: #246CB7;
            z-index: 15;
            position: relative;
        }
        figure {
            width: 59rem;
            height: 34.25rem;
            background: url('../../assets/temp/map.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            box-shadow: 0px 26px 77px rgba(189, 205, 221, 0.33);
            border-radius: 8px;
            border: 3.75rem solid #fff;
            display: flex;
            flex-shrink: 0;

        }
    }

  }
}

@media screen and (max-width: 768px){
.appeal-main {

  h1 {
    font-size: 2.5rem;
    line-height: 125%;
    width: 100%;

    margin-bottom: .75rem;
}

  &__wrapper {
    width: 91%;

    .complementary-step {
        margin-left: 12.5rem;
        &__number {
            margin-bottom: 0.325rem;

            span:first-child {
                font-weight: bold;
                font-size: 3.75rem;
                line-height: 1;
            }
            span:last-child  {
                font-size: 0.875rem;
                margin-left: 0.5rem;
            }
        }
        &__number::before {
            content: '';
            top: -3rem;
            left: -2rem;
        }
        button {
            margin-bottom: 3rem;
        }
    }

    .appeal-content {
        padding-top: 5.375rem;
        background-size: contain;

        &__information{
            flex-wrap: wrap;
            position: relative;
            margin-bottom: 5rem;

            div:nth-child(1){
                width: 80%;
                padding-bottom: 0;
                margin-bottom: 2.75rem;
            }
            div:nth-child(2){
                width: 50%;
                margin-bottom: 2.5rem;
            }
            div:nth-child(3){
                width: 50%;
                margin-bottom: 2.5rem;
            }
            .filed,
            .resolved {
                margin-right: 0.875rem;

                span:first-child {
                    font-size: 0.875rem;
                    line-height: 141%;
                    margin-bottom: 1rem;
                }
                span:last-child {
                    font-size: 3.75rem;
                    line-height: 1;
                }
            }

            .result {
                flex-direction: row-reverse;
                align-items: flex-end;
                span:first-child {
                    font-weight: 600;
                    font-size: 0.75rem;
                    line-height: 141%;
                    margin-left: 0.5rem;
                }
                span:last-child {
                    font-weight: bold;
                    font-size: 1.875rem;
                    line-height: 1;
                }

            }
            .group-button {
                position: absolute;
                left: 0;
                top: 100%
            }

        }
        &__recent{
            margin-bottom: 6.125rem;
            flex-wrap: wrap;

            &-wrapper {
                display: flex;
                justify-content: space-between;
            }

            &-tags {
                display: flex;
                flex-wrap: wrap;
                width: 21.5rem;
                margin-right: 0rem;
                span {
                    color: #246CB7;
                    overflow: hidden;
                    max-width: 9.4rem;
                    white-space: nowrap;
                }
            }
        }
        .card_container{
            .appeal {
                width: 21.5rem;
            }
            .appeal:not(:nth-child(2)) {
                display: none;
            }
        }

    }

    .appeal-steps {
        h1 {
            margin-bottom: 6.25rem;
        }
        .solution-problem{

            &__steps {
                margin-bottom: 9rem;
                flex-wrap: wrap;

                .complementary-step {
                    margin-left: 12.5rem;
                    margin-top: 2rem;
                    &__number {
                        margin-bottom: 3.325rem;

                        span:first-child {
                            font-weight: bold;
                            font-size: 3.75rem;
                            line-height: 1;
                        }
                        span:last-child  {
                            font-size: 0.875rem;
                            margin-left: 0.5rem;
                        }
                    }
                    &__number::before {
                        content: '';
                        top: -2rem;
                        left: 2rem;
                    }
                    button {
                        margin-bottom: 3rem;
                    }
                }

                .step-number {
                    width: 10rem;
                    height: 10rem;
                    margin-top: -3rem;
                    margin-right: 2.6rem;
                    span {
                        font-size: 2.5rem;
                        line-height: 0.9;
                    }
                    svg {
                        display: none;
                    }
                    .tablet{
                        display: unset;
                        width: 0.2rem;
                        left: 50%;
                        top: 90%;
                        height: 34.5rem;
                    }
                }
                .step-title {
                    font-size: 1.125rem;
                    line-height: 133%;
                }
                .share-social{
                    margin-top: -.6rem;
                }
                .description-step {
                    width: 69%;
                }
            }
            &__steps:nth-child(2) {
                margin-bottom: 9rem;
                .step-number{
                    order: 1;
                    svg {
                        display: none;
                    }
                    .tablet{
                    display: unset;
                    width: 0.2rem;
                    left: 50%;
                    top: 90%;
                    height: 38.5rem;
                }
                }
                .description-step{
                    order: 2;
                }
                .complementary-step{
                    order: 3;
                    margin-left: 0;
                    &__appeal-card {
                        width: 28rem;
                        position: relative;
                        margin-top: 2rem;
                        margin-left: 12rem;
                        min-height: 15rem;

                        .appeal:nth-child(1) {
                            transform: scale(0.6);
                            top: -6rem;
                            left: -7rem;
                        }
                        .appeal:nth-child(2) {
                            transform: scale(0.8);
                            top: 0;
                            left: 3rem;
                        }
                    }
                }
            }
            &__steps:nth-child(3) {

                .complementary-step{
                    width: 30rem;
                    margin-top: 1rem;
                    background-position-x: left;
                    .angle{
                        position: absolute;
                        left: 32%;
                        bottom: 94%;
                        width: 3rem;
                    }
               }
            }

        }
    }

    .appeal-maps {
        flex-direction: column;
        padding-bottom: 3.125rem;

        &__total-appeals {
            display: flex;
            flex-wrap: wrap;
        }

        &__title {
            width: 65%;
            margin-bottom: 0;
            margin-top: -2rem;
        }
        &__number-title {
            font-weight: 600;
            font-size: 1rem;
            line-height: 150%;
            color: #246CB7;
            z-index: 15;
            position: relative;
        }
        figure {
            width: 100%;
            height: 19rem;
            background-size: cover;
            border: 1.375rem solid #fff;
        }
    }

  }
}
}
@media screen and (max-width: 420px){
    .appeal-main {

  h1 {
    font-size: 1.375rem;
    margin-bottom: .75rem;
}

  &__wrapper {
    width: 91%;

    .complementary-step {

        &__number {
            margin-bottom: 3.325rem;

            span:first-child {
                font-weight: bold;
                font-size: 3.75rem;
                line-height: 1;
            }
            span:last-child  {
                font-size: 0.875rem;
                margin-left: 0.5rem;
            }
        }
        &__number::before {
            content: '';
            top: -2rem;
        }
        button {
            margin-bottom: 2rem;
        }
    }

    .appeal-content {
        padding-top: 2.375rem;
        background-size: contain;

        &__information{
            flex-wrap: wrap;
            position: relative;
            margin-bottom: 9rem;

            div:nth-child(n){
                width: 100%;
            }

            .filed,
            .resolved {
                margin-right: 0.875rem;
            }
            .group-button {
                flex-direction: column-reverse;
                button:not(:last-child){
                    margin-right: 0;
                    margin-top: 0.75rem;
                }
            }

        }
        &__recent{
            margin-bottom: 6.125rem;
            position: relative;

            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-tags {
                flex-wrap: nowrap;
                width: 100%;
                overflow: auto;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                h3{
                    position: absolute;
                    top: 3.325rem;
                }
                span {
                    color: #246CB7;
                    overflow: unset;
                    max-width: unset;
                    white-space: nowrap;
                    scroll-snap-align: start;
                }
            }
            .card_container{
                overflow: auto;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                    .appeal {
                        display: flex !important;
                        width: 100%;
                        flex-shrink: 0;
                        scroll-snap-align: start;
                    }
                }
        }
        &__header {
            .btn-blue {
                position: absolute;
                top: 106%;
                width: 100%;
                text-align: center;
            }
        }
        .appeal {
            width: 100%;
        }

    }

    .appeal-steps {
        h1 {
            margin-bottom: 3.25rem;
        }
        .solution-problem{

            &__steps:nth-child(n) {
                margin-bottom: 4rem;
                position: relative;
                .step-bg {
                   display: none;
                }
                .step-number {
                    width: 5.5rem;
                    height: 5.5rem;
                    margin-top: -1rem;
                    margin-left: -1rem;
                    font-size: 0.75rem;
                    span{
                        font-size: 1.375rem;
                    }
                    .tablet{
                        display: none;
                    }
                }
                .step-line {
                    width: 100%;
                }

                .step-title {
                    font-size: 1.125rem;
                    line-height: 133%;
                    position: absolute;
                    top: 0;
                    left: 5rem;
                }
                .share-social{
                    margin-top: -.6rem;
                }
                .description-step {
                    width: 100%;
                    margin-bottom: 2rem;
                }
                .complementary-step{
                    margin-left: 0;
                    margin-top: 0rem;
                    background-position-y: 0;

                    &__appeal-card {
                        margin-top: 0;
                        margin-left: 1rem;

                        .appeal:nth-child(1) {
                            transform: scale(0.6);
                            top: -6rem;
                            left: -7rem;
                        }
                        .appeal:nth-child(2) {
                            transform: scale(0.7);
                            top: 0;
                            left: -1rem;
                        }
                    }
                    &__quote{
                        font-size: 0.75rem;
                    }
                }
            }

        }
    }

    .appeal-maps {
        flex-direction: column;
        padding-bottom: 3.125rem;

        &__title {
            font-size: 1.375rem;
            margin-bottom: 1rem;
            margin-top: 0;
        }
        &__number-title {
            margin-left: 2rem;
        }
        .complementary-step {
            &__number {
                margin-left: 2rem;
            }
        }
        figure {
            height: 23.25rem;
            border: 0;
        }
        .btn-red {
            width: 100%;
            margin-bottom: 2.125rem;
        }
    }

  }
}
}

</style>
