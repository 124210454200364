<template>
  <div class="carousel-wrapper">
    <div class="carousel" ref="carousel" v-on:scroll="onScroll">
      <slot/>
      <div v-if="correction > 0" class="correction" :style="`min-width: ${$refs.carousel.offsetWidth / numberItems * correction}px; min-height: 100%;display: block;`"></div>
    </div>
    <div v-if="numberItems !== Number(count)" class="slider__points">
        <span v-for="(item, i) in Number(count)" @click="changeSlider(i)" :class="{  'active-point': carouselData === i}" :key="`points${i}`" class="point"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    count: {
      type: [Number, String],
      default: 0
    }
    // position_points: {
    //   type: String,
    //   default: 'center'
    //   // center, left, rigth
    // }
  },
  data () {
    return {
      carouselData: 0,
      numberItems: 0,
      correction: 0
    }
  },

  mounted () {
    this.$nextTick(() => {
      // console.log(this.widthItem)
      this.numberItems = Math.round(this.$refs.carousel.offsetWidth / (Number(this.widthItem)))
      this.correction = this.numberItems !== Number(this.count) ? this.numberItems - 1 : 0
    })
  },

  computed: {
    widthItem () {
      const carousel = this.$refs.carousel
      return Math.trunc(carousel.scrollWidth / (Number(this.count)))
    },
    positionPoints () {
      return this.position_points === 'center' ? 'center' : this.position_points === 'left' ? 'flex-start' : 'flex-end'
    }
  },

  methods: {
    onScroll (event) {
      const position = Math.round((event.target.scrollWidth - event.target.scrollLeft) / this.widthItem)
      const value = this.count - position + this.correction
      if (this.carouselData !== value) this.carouselData = value
    },
    changeSlider (delta) {
      if (this.carouselData !== delta) {
        const a = delta - this.carouselData
        const carousel = this.$refs.carousel
        carousel.scrollTo(carousel.scrollLeft + this.widthItem * a, 0)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.carousel-wrapper {
  display: flex;
  flex-direction: column;
}
.carousel {
  display: flex;
  flex: 1;
  justify-content: space-between;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
}
.slider__points{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1.25rem;;

      .point{
        display: block;
        width: 0.312rem;
        height: 0.312rem;
        background: #9CA0A7;
        cursor: pointer;
        border-radius: 50%;
        margin-right: 0.43rem;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .active-point{
      background-color: #E9242A !important;
      width: 0.437rem !important;
      height: 0.437rem !important;
    }
  @media screen and (max-width: 768px){
    .carousel {
      display: inline-flex;
      overflow: auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 420px){
    .carousel {
      width: 100%;
    }
  }
</style>
